import { mapGetters } from "vuex";
// import { getUserMenu } from "@/service/userManagement/index";
import { getUserMenu } from "../../../service/userManagement/index";
export default {
  name: "wheather",
  data() {
    return {
      modifyPwd: {
        visible: false,
        userId: sessionStorage.getItem("userId"),
        userName: "",
        systemName: "",
      },
      //编辑页面数据
      vipMag: {
        list: {
          query: {
            pageIndex: 1,
            pageSize: 1000,
            name: "",
            phone: "",
            unitId: sessionStorage.getItem("unitId"),
            vipType: 1,
            valid: 0,
          },
          total: 0,
          results: [],
        },
      },
      isvipLoop: true,
      istrainingLoop: true,
      mueData: [],
    };
  },
  methods: {
    grMue() {
      getUserMenu({ userId: sessionStorage.getItem("userId") }).then((res) => {
        console.log("res", res);
        let mueData = res.data.filter((item) => {
          return item.menuText === "VIP管理";
        });
        console.log("mueData", mueData);
      });
    },
    modPwd() {
      this.modifyPwd.visible = true;
    },
    handleReceive() {
      this.modifyPwd.visible = false;
    },
    Smlink() {
      // window.location.href = '/static/wy/sms.htm';
      window.open("/static/wy/sms.htm");
    },
    logOut() {
      // this.$router.push('/login');
      this.$store.dispatch("user/loginOut");
    },
    // 查询列表
    queryvipManage() {
      let querys = this.vipMag.list.query;
      this.$store
        .dispatch("StoreDataQuery/setQueryByNews", querys)
        .then((res) => {
          if (this.getNews > 0) {
            this.isvipLoop = true;
          } else {
            this.isvipLoop = false;
          }
          if (this.getAbnormalNum > 0) {
            this.istrainingLoop = true;
          } else {
            this.istrainingLoop = false;
          }
        });
    },
    // 本校对比异常
    getLocalTrainAbnormalListCount2() {
      let querys = {
        begin: this.$moment()
          .add(-1, "days")
          .format("YYYYMMDD"),
        end: this.$moment().format("YYYYMMDD"),
        readTag: -1,
        unitId: sessionStorage.getItem("unitId"),
      };
      this.$store
        .dispatch("StoreDataQuery/setQueryByAbnormalList", querys)
        .then((res) => {
          if (this.getNews > 0) {
            this.isvipLoop = true;
          } else {
            this.isvipLoop = false;
          }
          if (this.getAbnormalNum > 0) {
            this.istrainingLoop = true;
          } else {
            this.istrainingLoop = false;
          }
        });
    },
    pageToVip(params) {
      if (params == "/vipManagement") {
        this.isvipLoop = false;
      } else {
        this.istrainingLoop = false;
      }
      this.$router.push({ path: params, query: { key: "loop" } });
    },
  },
  computed: {
    ...mapGetters(["getNews", "getAbnormalNum"]),
  },
  created() {},
  components: {
    ModifyPassword: (resolve) =>
      require(["@/components/modifyPassword/index.vue"], resolve),
  },
  mounted() {
    this.grMue();
    this.getLocalTrainAbnormalListCount2();
    this.queryvipManage();
    this.modifyPwd.userName = sessionStorage.getItem("userName");
    this.modifyPwd.systemName = sessionStorage.getItem("eName");
    let menus = JSON.parse(sessionStorage.getItem("menus"));
    // for (let item of menus) {
    //   if (item) {
    //     if (item.menuText === "VIP管理") {
    //       this.queryvipManage();
    //     }
    //   }
    // }
  },
  activated() {
    this.modifyPwd.userName = sessionStorage.getItem("userName");
    this.modifyPwd.systemName = sessionStorage.getItem("eName");
    let menus = JSON.parse(sessionStorage.getItem("menus"));
    // for (let item of menus) {
    //   if (item) {
    //     if (item.menuText === "VIP管理") {
    //       this.queryvipManage();
    //     }
    //   }
    // }
  },
};
